import { useState } from 'react';
import { bikes } from './data/data';
import logo from './barayan.png';
import './App.css';
import './jquery.js';


function App() {

  const [bike, setBike] = useState(bikes[0]);


  return (
    <div className="App">
      <header className="header">
        <a href='https://suzukibarayan.com.sa/' target="_blank" rel="noreferrer">
          <img src={logo} alt="logo" />
        </a>
        <p className="title">
          حاسبة الضريبة المضافة
        </p>
      </header>
      <div className="calculator">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 well">
              <form className="form-horizontal">
                <div className="form-group">
                  <label className="control-label col-sm-2" htmlFor="value1">المبلغ</label>
                  <div className="col-sm-10">
                    <input type="number" name="value1" id="value1" className="form-control rounded" min="0" placeholder="سعر الدراجة" required />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-2" htmlFor="value2" hidden>Value 2</label>
                  <div className="col-sm-10">
                    <input type="number" name="value2" id="value2" className="form-control" min="0" value="0.15" readOnly required hidden />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-2" htmlFor="fees" >الرسوم</label>
                  <div className="col-sm-10">
                    <input type="number" name="fees1" id="fees1" className="form-control rounded" min="0" placeholder="الاستمارة" required />
                  </div>
                  <div className="col-sm-10">
                    <input type="number" name="fees2" id="fees2" className="form-control rounded" min="0" placeholder="التأمين" required />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="form-group">
            <ul>
              <div className="col-sm-10">
                <li>
                  <input type="text" name="vat" id="vat" className="form-control calcs" readOnly disabled />
                  <label className="control-label col-sm-2" htmlFor="sum">الضريبة <span className="script">(15%)</span> </label>
                </li>
              </div>
              <div className="col-sm-10">
                <li>
                  <input type="text" name="sum" id="sum" className="form-control calcs" readOnly disabled />
                  <label className="control-label col-sm-2" htmlFor="sum">المجموع </label>
                </li>
              </div>
            </ul>
            <hr></hr>
            <div className="form-group">
              <div className="col-sm-10">
                <li>
                  <input type="text" name="grandtotal" id="grandtotal" className="form-control calcs" readOnly disabled />
                  <label className="control-label col-sm-2" htmlFor="grandtotal">المجموع الكلي </label>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
