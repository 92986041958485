import $ from 'jquery';

$(function () {
    $('#value1, #value2, #fees1, #fees2').keyup(function () {
        var value1 = parseFloat($('#value1').val()) || 0;
        var value2 = parseFloat($('#value2').val()) || 0;
        var fees1 = parseFloat($('#fees1').val()) || 0;
        var fees2 = parseFloat($('#fees2').val()) || 0;
        var tax = value1 * value2;
        var pricewtax = value1 + tax;
        var grandtotal = pricewtax + fees1 + fees2;

        $('#vat').val((' ر.س ') + tax.toLocaleString('en-US'));
        $('#sum').val((' ر.س ') + pricewtax.toLocaleString('en-US'));
        $('#grandtotal').val((' ر.س ') + grandtotal.toLocaleString('en-US'));
    });

});