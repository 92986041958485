export const bikes = [
    {
        "id": 0,
        "name": "GIXXER 250 F",
        "price": "10950"
    },
    {
        "id": 1,
        "name": "GIXXER 250",
        "price": "9300"
    },
    {
        "id": 2,
        "name": "GIXXER 150 F",
        "price": "7550"
    },
    {
        "id": 3,
        "name": "GIXXER 150",
        "price": "6900"
    }
]